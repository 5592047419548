//公共的header 组件

import React, { useState } from "react";

import { Col, Menu, Modal, Row } from "antd";

import { Link } from "gatsby";


import * as styles from "./index.module.scss";

import Logo from "@src/images/Logo.svg";
import MenuIcon from "@src/images/Vector.svg";

const headerData = [
  {
    name: "首页",
    to: "/",
  },
  {
    name: "Shoprise有品",
    to: "/shoprise/",
  },
  {
    name: "特性展示",
    // to: "/pricing",
    son: [
      { name: "功能特性", to: "/product-features/" },
      {
        name: "模板展示",
        to: "/theme/",
      },
    ],
  },

  {
    name: "套餐定价",
    to: "/pricing/",
  }, {
    name: "推广服务",
    to: "/advertising/",
  },
  {
    name: "服务支持",
    son: [
      {
        name: "常见问题",
        to: "/question/",
      },
      // {
      //   name: "帮助中心",
      //   to: "/helpcenter/",
      // },
      // {
      //   name: "投诉建议",
      //   to: "/complaints/",
      // },
    ],
  },
  {
    name: "关于我们",
    son: [
      {
        name: "公司介绍",
        to: "/aboutus/",
      },
      {
        name: "合作伙伴",
        to: "/partner/",
      },
    ],
  },
];

const handleRegister = function () {
  // window.location.href = " https://accounts.hotishop.com/user/register";
};
const handleLogin = () => {
  window.location.href = " https://accounts.hotishop.com/user/login";
};
const IndexHeader = () => {
  const [visible, setVisible] = useState(false);
  const handleChangeVisible = () => {
    setVisible(!visible);
  };
  const [current, setCurrent] = useState("");
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <div style={{ backgroundColor: "#FFF" }} className={styles.header}>
      <Row>
        {/* pc端显示 */}
        <Col lg={7} md={5} xs={12}>
          <Link to="/">
            <img src={Logo} className={styles.logo} alt="LOGO" />
          </Link>
        </Col>
        <Col lg={14} md={14} xs={0}>
          <div className={styles.linkContainer}>
            <Menu
              onClick={handleClick}
              selectedKeys={[current]}
              mode="horizontal"
              style={{
                border: "none",
                lineHeight: "95px",
                fontSize: "16px",
              }}
            >
              {headerData.map((item) => {
                return item.to ? (
                  <Menu.Item key={item.name}>
                    <Link to={item.to}>{item.name}</Link>
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    key={item.name}
                    title={item.name}
                    popupOffset={[-45, 0]}
                  >
                    {item.son.map((item) => {
                      return (
                        <Menu.Item key={item.name}>
                          <Link to={item.to}>{item.name}</Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              })}
            </Menu>
          </div>
        </Col>
        {/* <Col lg={4} md={5} xs={0}>
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                paddingRight: "29px",
                minWidth: "68px",
                padding: "20px 20px",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              onClick={handleLogin}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              role="button"
              tabIndex={0}
            >
              <img src={loginIcon} alt="loginIcon" width='22px' style={{ marginRight: "8px" }} />
              登录
            </div>
            <Button
              type="primary"
              shape="round"
              style={{
                backgroundColor: "#111111",
                height: "50px",
                padding: "10px",
                width: "150px",
                border: "none",
                marginTop: "8px",
                fontSize: "16px",
              }}
              onClick={handleRegister}
            >
              30天免费使用
            </Button>
          </div>
        </Col> */}
        {/* 移动端页面 */}
        <Col md={0} xs={{ span: 6, offset: 6 }}>
          <div
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onClick={handleChangeVisible}
          >
            <img
              src={MenuIcon}
              alt="MenuIcon"
              style={{ float: "right", margin: "16px 20px", height: "15px" }}
            />
          </div>
        </Col>
        <Modal
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          mask={false}
          getContainer={false}
          style={{ top: 0 }}
          zIndex={5}
          transitionName=""
          bodyStyle={{ padding: "24px 0" }}
        >
          <Menu style={{ border: "none", marginTop: "40px" }} mode="inline">
            {headerData.map((item) =>
              item.to ? (
                <Menu.Item key={item.name}>
                  <Link to={item.to}>{item.name}</Link>
                </Menu.Item>
              ) : (
                <Menu.SubMenu key={item.name} title={item.name}>
                  {item.son.map((item) => (
                    <Menu.Item
                      key={item.name}
                      style={{ background: "#F9FBFE" }}
                    >
                      <Link to={item.to}>{item.name}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            )}
          </Menu>
          {/* <div style={{ padding: "0 16px" }}>
            <Button
              type="primary"
              style={{ width: "100%", height: "36px", marginTop: "60px" }}
              shape="round"
              onClick={handleLogin}
            >
              登录
            </Button>
          </div> */}
          {/* <div style={{ padding: "0 16px" }}>
            <Button
              type="primary"
              style={{ width: "100%", height: "36px", marginTop: "20px" }}
              shape="round"
              onClick={handleRegister}
            >
              30天免费使用
            </Button>
          </div> */}
        </Modal>
      </Row>
    </div>
  );
};

export default IndexHeader;
